const config = {
  siteTitle: "Ajay Ajaal",
  siteTitleShort: "Ajay Ajaal",
  siteTitleAlt: "Ajay Ajaal",
  siteLogo: "/logos/tania.jpg",
  siteUrl: "https://www.ajayajaal.com",
  // repo: 'https://github.com/ajayajaal/ajayajaal',
  pathPrefix: "",
  dateFromFormat: "YYYY-MM-DD",
  dateFormat: "MMMM Do, YYYY",
  siteDescription:
    "Ajay Ajaal is data architect specializing in digital data who breaks down complex concepts into accessible and easy to understand ideas.",
  siteRss: "/rss.xml",
  // googleAnalyticsID: 'UA-42068444-1',
  disqusShortname: "ajayajaal",
  postDefaultCategoryID: "Tech",
  userName: "Ajay",
  userEmail: "me@ajayajaal.com",
  userTwitter: "ajayajaal",
  userLocation: "Toronto, Canada",
  userAvatar: "https://api.adorable.io/avatars/150/test.png",
  userDescription:
    "I build data pipelines and bring juniors into the data industry.",
  menuLinks: [
    {
      name: "Me",
      link: "/me/"
    },
    {
      name: "Articles",
      link: "/blog/"
    }
    // {
    //   name: 'Contact',
    //   link: '/contact/',
    // },
  ],
  themeColor: "#3F80FF", // Used for setting manifest and progress theme colors.
  backgroundColor: "#ffffff"
};

// Make sure pathPrefix is empty if not needed
if (config.pathPrefix === "/") {
  config.pathPrefix = "";
} else {
  // Make sure pathPrefix only contains the first forward slash
  config.pathPrefix = `/${config.pathPrefix.replace(/^\/|\/$/g, "")}`;
}

// Make sure siteUrl doesn't have an ending forward slash
if (config.siteUrl.substr(-1) === "/")
  config.siteUrl = config.siteUrl.slice(0, -1);

// Make sure siteRss has a starting forward slash
if (config.siteRss && config.siteRss[0] !== "/")
  config.siteRss = `/${config.siteRss}`;

module.exports = config;

const projects = [
  {
    title: "JadeAt.Work",
    path: "https://jadeat.work",
    description:
      "Training junior's through mentorship inside of enterprise organizations."
  },
  {
    title: "Junior Mentorship",
    path: "https://juniormentorship.com",
    description:
      "Solving the 'I need experience to get a job but I need a job to get experience' problem."
  },
  {
    title: "The Libyan Report",
    path: "https://thelibyanreport.com",
    description: "A fully automated minimalist news agency."
  },
  {
    title: "Startup News 101",
    path: "https://twitter.com/startupnews101",
    description:
      "A twitter bot that searches the interwebs and brings you startup news."
  }
];

export default projects;

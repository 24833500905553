const speaking = [
  {
    title:
      "Adobe Summit 2019 - Adobe Launch whats new and whats coming with Corey Spencer",
    path:
      "https://events.rainfocus.com/widget/adobe/as19/sessioncatalog?search=S609"
  },
  {
    title:
      "People Analytics - How SharpestMinds and JadeAt.Work (work) together",
    path: "https://twitter.com/AnalyticsTo"
  }
];

export default speaking;

const publications = [
  {
    title: "How Telus moved 50% of its web traffic to Adobe Launch",
    path:
      "https://labs.telus.com/blog/how-telus-moved-50-web-traffic-to-adobe-launch"
  },
  {
    title: "Master The Digital Analytics Journey At Telus",
    path: "https://labs.telus.com/blog/mastering-web-analytics-journey-telus/"
  },
  {
    title: "Snapshot of the Data Platform Team",
    path: "https://labs.telus.com/blog/snapshot-data-platform-team"
  }
  // {
  //   title: 'How to Write Comments in JavaScript',
  //   path: 'https://www.digitalocean.com/community/tutorials/how-to-write-comments-in-javascript',
  // },
  // {
  //   title: 'How to Work with Strings in JavaScript',
  //   path: 'https://www.digitalocean.com/community/tutorials/how-to-work-with-strings-in-javascript',
  // },
  // {
  //   title: 'How To Index, Split, and Manipulate Strings in JavaScript',
  //   path: 'https://www.digitalocean.com/community/tutorials/how-to-work-with-strings-in-javascript',
  // },
  // {
  //   title: 'How To Do Math in JavaScript with Operators',
  //   path:
  //     'https://www.digitalocean.com/community/tutorials/how-to-do-math-in-javascript-with-operators',
  // },
  // {
  //   title: 'Understanding Arrays in JavaScript',
  //   path: 'https://www.digitalocean.com/community/tutorials/understanding-arrays-in-javascript',
  // },
  // {
  //   title: 'How To Use Array Methods in JavaScript: Mutator Methods',
  //   path:
  //     'https://www.digitalocean.com/community/tutorials/how-to-use-array-methods-in-javascript-mutator-methods',
  // },
  // {
  //   title: 'How To Use Array Methods in JavaScript: Accessor Methods',
  //   path:
  //     'https://www.digitalocean.com/community/tutorials/how-to-use-array-methods-in-javascript-accessor-methods',
  // },
  // {
  //   title: 'How To Use Array Methods in JavaScript: Iteration Methods',
  //   path:
  //     'https://www.digitalocean.com/community/tutorials/how-to-use-array-methods-in-javascript-iteration-methods',
  // },
  // {
  //   title: 'Understanding Objects in JavaScript',
  //   path: 'https://www.digitalocean.com/community/tutorials/understanding-objects-in-javascript',
  // },
  // {
  //   title: 'How to Use Object Methods in JavaScript',
  //   path:
  //     'https://www.digitalocean.com/community/tutorials/how-to-use-object-methods-in-javascript',
  // },
  // {
  //   title: 'How To Write Conditional Statements in JavaScript',
  //   path:
  //     'https://www.digitalocean.com/community/tutorials/how-to-write-conditional-statements-in-javascript',
  // },
  // {
  //   title: 'How To Use the Switch Statement in JavaScript',
  //   path:
  //     'https://www.digitalocean.com/community/tutorials/how-to-use-the-switch-statement-in-javascript',
  // },
  // {
  //   title: 'Using While and Do...While Loops in JavaScript',
  //   path:
  //     'https://www.digitalocean.com/community/tutorials/using-while-and-do-while-loops-in-javascript',
  // },
  // {
  //   title: 'Understanding For Loops in JavaScript',
  //   path:
  //     'https://www.digitalocean.com/community/tutorials/how-to-construct-for-loops-in-javascript',
  // },
  // {
  //   title: 'Understanding Functions in JavaScript',
  //   path: 'https://www.digitalocean.com/community/tutorials/how-to-define-functions-in-javascript',
  // },
  // {
  //   title: 'Understanding Date and Time in JavaScript',
  //   path:
  //     'https://www.digitalocean.com/community/tutorials/understanding-date-and-time-in-javascript',
  // },
  // {
  //   title: 'Understanding Prototypes and Inheritance in JavaScript',
  //   path:
  //     'https://www.digitalocean.com/community/tutorials/understanding-prototypes-and-inheritance-in-javascript',
  // },
  // {
  //   title: 'Understanding Classes in JavaScript',
  //   path: 'https://www.digitalocean.com/community/tutorials/understanding-classes-in-javascript',
  // },
  // {
  //   title: 'Understanding Variables, Scope, and Hoisting in JavaScript',
  //   path:
  //     'https://www.digitalocean.com/community/tutorials/understanding-variables-scope-hoisting-in-javascript',
  // },
  // {
  //   title: 'How To Upload a File to Object Storage with Node.js',
  //   path:
  //     'https://www.digitalocean.com/community/tutorials/how-to-upload-a-file-to-object-storage-with-node-js',
  // },
  // {
  //   title: 'Introduction to the DOM',
  //   path: 'https://www.digitalocean.com/community/tutorials/introduction-to-the-dom',
  // },
  // {
  //   title: 'Understanding the DOM Tree and Nodes',
  //   path: 'https://www.digitalocean.com/community/tutorials/understanding-the-dom-tree-and-nodes',
  // },
  // {
  //   title: 'How To Access Elements in the DOM',
  //   path: 'https://www.digitalocean.com/community/tutorials/how-to-access-elements-in-the-dom',
  // },
  // {
  //   title: 'How To Traverse the DOM',
  //   path: 'https://www.digitalocean.com/community/tutorials/how-to-traverse-the-dom',
  // },
  // {
  //   title: 'How To Make Changes to the DOM',
  //   path: 'https://www.digitalocean.com/community/tutorials/how-to-make-changes-to-the-dom',
  // },
  // {
  //   title: 'How To Modify Attributes, Classes, and Styles in the DOM',
  //   path:
  //     'https://www.digitalocean.com/community/tutorials/how-to-modify-attributes-classes-and-styles-in-the-dom',
  // },
  // {
  //   title: 'Understanding Events in JavaScript',
  //   path: 'https://www.digitalocean.com/community/tutorials/understanding-events-in-javascript',
  // },
  // {
  //   title: 'An Introduction to CSS Shapes',
  //   path: 'https://tympanus.net/codrops/2018/11/29/an-introduction-to-css-shapes',
  // },
  // {
  //   title: 'ES6 Syntax, Features, and Additions: A Reference Guide',
  //   path: 'https://www.telerik.com/blogs/es6-syntax-features-and-additions',
  // },
  // {
  //   title: 'Code Your First API With Node.js and Express: Understanding REST APIs',
  //   path:
  //     'https://code.tutsplus.com/tutorials/code-your-first-api-with-nodejs-and-express-understanding-rest-apis--cms-31697',
  // },
  // {
  //   title: 'Code Your First API With Node.js and Express: Set Up the Server',
  //   path:
  //     'https://code.tutsplus.com/tutorials/code-your-first-api-with-nodejs-and-express-set-up-the-server--cms-31698',
  // },
  // {
  //   title: 'Code Your First API With Node.js and Express: Connect a Database',
  //   path:
  //     'https://code.tutsplus.com/tutorials/code-your-first-api-with-nodejs-and-express-connect-a-database--cms-31699',
  // },
  // {
  //   title: 'JavaScript Operators, Conditionals & Functions',
  //   path: 'https://www.sitepoint.com/javascript-operators-conditionals-functions',
  // },
  // {
  //   title: 'A Beginner’s Guide to JavaScript Variables and Datatypes',
  //   path: 'https://www.sitepoint.com/beginners-guide-javascript-variables-and-datatypes',
  // },
];

export default publications;

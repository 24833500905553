import React, { Component } from "react";

class Footer extends Component {
  render() {
    return (
      <footer className="footer container">
        <small>Made with ☕ and 🍕</small>
      </footer>
    );
  }
}

export default Footer;
